import './App.css';
import './Assets/css/icon-set/style.css';
import './Assets/css/bootstrap.min.css';
import Navbar from "../src/Screens/Component/Navbar/navbar";
import Login from "./Screens/Auth/Login";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Users from "./Screens/Users/Users";
import UsersDetails from "./Screens/Users/UserDetails";
import Profile from "./Screens/Profile/Profile";
import Support from "./Screens/Support/support";
import Interest from "./Screens/Interest/Interest";
import Report from "./Screens/Reports/report";
import Faqcategory from "./Screens/Faq/Faqcategory";
import Faq from "./Screens/Faqs/Faqlist";
import Language from "./Screens/Language/Language";
import Setting from "./Screens/Settings/Setting";
import Subscription from "./Screens/Subscription/Subscription";
import Notification from "./Screens/Notification/Notification";
import Question from "./Screens/Question/Question";
import Purchage_history from "./Screens/Purchage_history/Purchage_history";
import Country from "./Screens/Country/Country";


import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navbar pagename={"Dashboard"} page={<Dashboard />} />}
        />
        <Route
          path="/users"
          element={<Navbar pagename={"Users"} page={<Users />} />}
        />
        <Route path="/users/:userId" element={<Navbar pagename={"UsersDetails"} page={<UsersDetails />} />} />


        <Route
          path="/profile"
          element={<Navbar pagename={"Profile"} page={<Profile />} />}
        />

        <Route
          path="/support"
          element={<Navbar pagename={"Support"} page={<Support />} />}
        />


        <Route
          path="/interest"
          element={<Navbar pagename={"Interest"} page={<Interest />} />}
        />

        <Route
          path="/Report"
          element={<Navbar pagename={"Report"} page={<Report />} />}
        />

        <Route
          path="/Faqcategory"
          element={<Navbar pagename={"Faqcategory"} page={<Faqcategory />} />}
        />
        <Route
          path="/Faq"
          element={<Navbar pagename={"Faq"} page={<Faq />} />}
        />

        <Route
          path="/Language"
          element={<Navbar pagename={"Language"} page={<Language />} />}
        />

        <Route
          path="/Setting"
          element={<Navbar pagename={"Setting"} page={<Setting />} />}
        />
        <Route
          path="/Notification"
          element={<Navbar pagename={"Notification"} page={<Notification />} />}
        />
        <Route
          path="/Subscription"
          element={<Navbar pagename={"Subscription"} page={<Subscription />} />}
        />
        <Route
          path="/Purchage_history"
          element={<Navbar pagename={"Purchage_history"} page={<Purchage_history />} />}
        />

        <Route
          path="/Question"
          element={<Navbar pagename={"Question"} page={< Question />} />}
        />

        <Route
          path="/Country"
          element={<Navbar pagename={"Country"} page={< Country />} />}
        />

        <Route
          path="/22"
          element={
            <Navbar
              pagename={"N/A"}
              page={
                <div className="container">
                  <div className="card">
                    <div className="card-header">
                      <h1>Welcome back Jim</h1>
                    </div>
                    <div className="card-body">
                      <p>Your account type is: Administrator</p>
                    </div>
                  </div>
                </div>
              }
            />
          }
        />
        <Route path="login" element={<Login></Login>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
