import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { post ,del} from '../../API/apiHelper';
import { add_faq_category, faq_category_delete ,IMAGE_BASE_URL,country_update} from '../../API/endpoints';
import { toast } from 'react-toastify';
import '../../Assets/css/CloseButton.css';

const CList = ({ category, onUpdate }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({ flag: '', id: '' });

  const handleShowEdit = () => {
    setModalData({ flag: category.flag, id: category.id });
    setShowEditModal(true);
  };
  const handleCloseEdit = () => setShowEditModal(false);

  

  const handleSave = async () => {
    await post(
      country_update,
      {
        flag: modalData.flag,
        id: modalData.id,
      },
      true
    );
    toast.success('Update Successfully');
    handleCloseEdit();
    onUpdate(); // Call the onUpdate callback to refresh data
  };

  return (
    <>
      <tr>
        <td className="text-center">{category.id}</td>
        <td className="text-center">{category.country_name || '-'}</td>
        <td className="text-center"> 
          
           <img
            src={IMAGE_BASE_URL+category.flag}
            className="avatar rounded-circle"
            alt=""
            width="40"
          />
          
          </td>
        <td>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-info" size="sm" onClick={handleShowEdit}>
              <i className="tio-edit"></i>
            </Button>
          </div>
        </td>
      </tr>

      {/* Modal for updating category */}
      <Modal show={showEditModal} onHide={handleCloseEdit}>
        <Modal.Header closeButton className="customModalHeader">
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCategoryName">
              <Form.Label>File</Form.Label>
              <Form.Control
        type="file"
        placeholder="Upload"
        accept="image/*" // Restrict to image files
        onChange={(e) => {
          const file = e.target.files[0]; // Get the selected file
          setModalData({ ...modalData, flag: file }); // Store the file object
        }}
      />

              <Form.Control
                type="hidden"
                placeholder="Category id"
                value={modalData.id}
                onChange={(e) =>
                  setModalData({ ...modalData, id: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CList;
